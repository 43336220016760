#cowebsite {
  position: fixed;
  z-index: 820;
  transition: transform 0.5s;
  background-color: rgba(10, 9, 9, 0.8);
  display: none;

  main {
    iframe {
      width: 100%;
      height: 100%;
      max-width: 100vw;
      max-height: 100vh;
    }
  }

  aside {
    background: gray;
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    #cowebsite-aside-holder {
      background: gray;
      height: 20px;
      flex: 1;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;

      img {
        width: 80%;
        pointer-events: none;
      }
    }

    #cowebsite-aside-buttons {
      display: flex;
      flex-direction: column;
      margin-bottom: auto;
      flex: 1;
      justify-content: start;

      #cowebsite-swipe {
        display: none;
        img {
          transform: rotate(0deg) !important;
          transform: scale(0.5);
        }
      }
    }

    .top-right-btn {
      transform: scale(0.5);
      cursor: url("./images/cursor_pointer.png"), pointer;
    }

    #cowebsite-other-actions {
      display: flex;
      margin-top: auto;
      visibility: hidden;
      justify-content: end;
      flex: 1;
    }
  }

  &-loader {
    width: 20%;

    #smoke {
      @for $i from 1 through 3 {
        #trail-#{$i} {
          @for $y from 1 through 3 {
            #trail-#{$i}-state-#{$y} {
              visibility: hidden;
            }
          }
        }
      }
    }
  }

  &-slot-main {
    z-index: 70 !important;
    background-color: rgba(10, 9, 9, 0);
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &-buffer {
    iframe {
      z-index: 45 !important;
      pointer-events: none !important;
      overflow: hidden;
      border: 0;
      position: absolute;

      &.pixel {
        height: 1px !important;
        width: 1px !important;
      }
    }

    .main {
      pointer-events: all !important;
      z-index: 821 !important;
    }

    .highlighted {
      pointer-events: all !important;
      padding: 4px;
    }

    .thumbnail {
      transform: scale(0.5, 0.5);
    }
  }

  .pixel {
    visibility: hidden;
    height: 1px;
    width: 1px;
  }
}
